<script setup>
import { computed, getCurrentInstance } from 'vue';
import ActionButton from './ActionButton.vue';

const props = defineProps({
    items: {
        type: Array,
        default: () => []
    }
})

const app = getCurrentInstance()

const contentClass = computed(() => {
    return `elevation-0 ${app.proxy.$vuetify.breakpoint.mdAndUp ? '' : ''}`
})
</script>

<template>
    <!-- <v-menu top offset-y nudge-bottom="8" :content-class="contentClass">
        <template #activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs"><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <div class="d-flex flex-column justify-center" :style="{ position: 'relative' }">
            <v-card outlined class="pa-2 elevation-2">
                <div class="d-flex align-center">
                    <ActionButton v-for="(item, index) in items" :key="index" :class="{ 'ml-2': index !== 0 }"
                        :text="item.text" :color="item.color" :action="item.action" inTable @click="item.onClick" />
                </div>
            </v-card>
            <div class="background darken-4" :style="{
                border: '1px solid black',
                height: '16px',
                clipPath: 'polygon(0 0, 5% 0, 10% 100%, 15% 0, 100% 0)'
            }" />
        </div>
    </v-menu> -->
    <div class="d-flex align-center actions-container justify-end">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
// .actions-container-center {
//     transform: translate(calc(50% + 16px), 0);
// }

.actions-container > *+* {
    margin-left: 8px;
}
</style>

