<script setup>
import { computed, reactive, ref } from "vue";
import {
  fetchBusinessFormDataOnMount,
  fetchBusinessFeeDataOnMount,
} from "@/utils/vue_helpers";
import { getCurrentBusinessPermit } from "@/utils/functions";
import BusinessDetails from "./BusinessDetails.vue";
import ContactPersonDetails from "./ContactPersonDetails.vue";
import BusinessLocationDetails from "./BusinessLocationDetails.vue";
import PermitDetails from "./PermitDetails.vue";
import FeesDialog from "./FeesDialog.vue";
import ViewBusiness from "../ViewBusiness.vue";
import { requestStatus } from "@/utils/constants";
import apiCall from "@/utils/api";
import {
  snackbarData,
  resetSnackbarOnMount,
  setSnackbar,
} from "@/components/utils/snackbar/logic";
import { routes } from "@/router/routes";
import { useGetLatePenalty } from "./renew_sbp_business_logic";

resetSnackbarOnMount();

const props = defineProps({
  business: {
    type: Object,
    default: () => null,
  },
});

const pages = {
  RENEW_PERMIT: 0,
  VIEW_BUSINESS: 1,
};
const currentPage = ref(pages.RENEW_PERMIT);
function goToViewBusiness(business) {
  renewedBusiness.value = business;
  openBillItemsDialog(false);
  currentPage.value = pages.VIEW_BUSINESS;
}

const businessCopy = reactive({ ...props.business, selected_fee: null });
const currentPermit = getCurrentBusinessPermit(props.business);
const currentPermitCopy = reactive({ ...currentPermit });

fetchBusinessFormDataOnMount();
fetchBusinessFeeDataOnMount();

const billItemsDialogModel = ref(false);
function openBillItemsDialog(open = true) {
  const businessHasBusinessActivity = !!props.business?.description_of_activity;
  if (businessHasBusinessActivity) {
    billItemsDialogModel.value = open;
  } else {
    setSnackbar({
      text: "Business has no business activity",
      color: "error",
      open: true,
    });
  }
}

const renewSbpRequestStatus = ref(requestStatus.NOT_SENT);
const renewingSbp = computed(() => {
  return renewSbpRequestStatus.value === requestStatus.SENDING;
});
const renewedBusiness = ref(null);
async function renewSbp() {
  const requestData = {
    ...currentPermitCopy,
    ...businessCopy,
  };
  const businessId = businessCopy.id;
  const url = `/api/client/business/renew-permit`;
  try {
    renewSbpRequestStatus.value = requestStatus.SENDING;
    const response = await apiCall({ url, data: requestData, method: "POST" });
    goToViewBusiness(response);
    snackbarData.text = "Permit renewed successfully";
    snackbarData.color = "success";
    snackbarData.open = true;
    renewSbpRequestStatus.value = requestStatus.COMPLETE;
  } catch (error) {
    renewSbpRequestStatus.value = requestStatus.ERROR;
    console.log(error);
    snackbarData.text = "Error renewing permit";
    snackbarData.color = "error";
    snackbarData.open = true;
  }
}

const latePenalty = useGetLatePenalty({
  sbpNumberRef: computed(() => currentPermit.sbp_number),
  permitValidToRef: computed(() => currentPermit.validity_to),
  isNotLiquorPermitRef: computed(() => Number(currentPermit.liquor_sale) !== 1),
});
</script>

<template>
  <v-container class="pt-md-3 pa-md-0">
    <v-row align="center" no-gutters>
      <v-col md="3">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <router-link :to="routes.viewBusinesses">
              <v-btn plain v-on="on" v-bind="attrs">
                <v-icon left class="">mdi-arrow-left</v-icon>
                <span class="text-caption text-none">Back to Businesses</span>
              </v-btn>
            </router-link>
          </template>
          <span> Back to Businesses </span>
        </v-tooltip>
      </v-col>

      <v-col md="6" class="text-md-h5 font-weight-bold primary--text text-md-center"> Renew Permit </v-col>

      <v-spacer />
    </v-row>

    <v-tabs-items class="px-4" v-model="currentPage" touchless>
      <v-tab-item>
          <v-row>
            <v-col cols="12">
              <BusinessDetails :business="businessCopy" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ContactPersonDetails :business="businessCopy" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <BusinessLocationDetails :business="businessCopy" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <PermitDetails
                :permit="currentPermitCopy"
                :business="businessCopy"
                :latePenaltyAmount="latePenalty.data.value"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto" md="12" class="">
              <v-card outlined>
                <v-card-text class="d-flex align-center">
                  <v-icon color="secondary">mdi-information-outline</v-icon>
                  <span class="ml-2"
                    >Please visit your respective Sub County Revenue office if
                    any of your details are incorrect or if you need to change
                    your business activity.
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn
                color="primary"
                class="text-none"
                @click="openBillItemsDialog(true)"
                :loading="latePenalty.isLoading.value"
              >
                <span>Submit</span>
                <v-icon class="white--text" right>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <FeesDialog
            :business="businessCopy"
            :permit="currentPermitCopy"
            :dialogOpen.sync="billItemsDialogModel"
            :loading="renewingSbp"
            :latePenaltyAmount="latePenalty.data.value"
            @renewSbpClicked="renewSbp"
          />
      </v-tab-item>
      <v-tab-item>
        <ViewBusiness :business="renewedBusiness" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
