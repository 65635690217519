<script setup>
import { businessFormData, businessFormDataNotEmpty } from '@/utils/vue_helpers'
import LabelledField from '@/components/utils/LabelledField.vue'
import { computed, ref, watchEffect } from 'vue';

const props = defineProps({
    business: {
        type: Object,
        default: null
    }
})

const tradeLicenseCode = computed(() => props.business.trade_license_code)
const businessActivities = ref([])

function setBusinessActivities() {
    const filteredBusinessActivities = []
    if (businessFormDataNotEmpty.value) {
        for (const businessActivity of businessFormData.value.businessactivity) {
            const businessActivityMatchesTradeLicenseCode = businessActivity.fee_code === tradeLicenseCode.value
            if (businessActivityMatchesTradeLicenseCode) {
                filteredBusinessActivities.push(businessActivity)
            }
        }
    }
    businessActivities.value = filteredBusinessActivities
}

function getSubpartFeeMatchingTradeLicenseAndZone() {
    let subpartFee = undefined
    if (businessFormDataNotEmpty.value) {
        subpartFee = businessFormData.value.sbp_fee.find(subpartFee => {
            const subpartFeeCodeMatchesTradeLicenseCode = subpartFee.fee_code === tradeLicenseCode.value
            const subpartZoneMatchesBusinessZone = subpartFee.zone_id === props.business.zone_id
            const feeMatches = subpartFeeCodeMatchesTradeLicenseCode && subpartZoneMatchesBusinessZone
            if (feeMatches) {
                return true
            }
            return false
        })
    }
    return subpartFee
}

function setTypeAndCategoryAndFeeAmount(tradeLicenseCode) {
    const subpartFee = getSubpartFeeMatchingTradeLicenseAndZone(tradeLicenseCode)
    const subpartFeeNotEmpty = subpartFee !== undefined
    if (subpartFeeNotEmpty) {
        props.business.business_type = subpartFee.fee_name
        props.business.fee_amount = subpartFee.amount
        props.business.business_category = subpartFee.schedulesubpart.schedulepart.part_name
    }
}

watchEffect(() => {
    setBusinessActivities()
    setTypeAndCategoryAndFeeAmount()
})

const descriptionOfActivityAsNumber = computed({
    get() {
        return Number(props.business.description_of_activity)
    },
    set(newVal) {
        props.business.description_of_activity = newVal
    }
})
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row justify-md="start">
            <v-col cols="auto">
                <!-- <div class="primary--text text-decoration-underline font-weight-bold">Business Details</div> -->
                <v-tabs>
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Business Details</v-tab>
                </v-tabs>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <LabelledField label="Trade License Code" required>
                    <v-autocomplete v-model="props.business.trade_license_code" :loading="!businessFormDataNotEmpty"
                        :items="businessFormData.sbp_fee" item-value="fee_code" item-text="fee_code" cache-items outlined
                        dense hide-no-data hide-details="auto" class="text-field-background" disabled />
                </LabelledField>
            </v-col>

            <v-col cols="6">
                <LabelledField label="Business Category" required>
                    <v-text-field outlined dense v-model="props.business.business_category" readonly
                        class="text-field-background" disabled hide-details="auto" />
                </LabelledField>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <LabelledField label="Business Type" required>
                    <v-text-field label="" outlined dense v-model="props.business.business_type" readonly
                        class="text-field-background" disabled hide-details="auto"></v-text-field>
                </LabelledField>
            </v-col>
            <v-col cols="6">
                <LabelledField label="Business Activity" required>
                    <v-autocomplete v-model="descriptionOfActivityAsNumber" :loading="!businessFormDataNotEmpty"
                        :items="businessActivities" item-value="id" item-text="activity_name" outlined dense
                        class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <LabelledField label="Business Name" required>
                    <v-text-field outlined dense v-model="props.business.business_name" class="text-field-background"
                        disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
            <v-col cols="6">
                <LabelledField label="Business Identification Number" required>
                    <v-text-field outlined dense v-model="props.business.identification_no" readonly
                        class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <LabelledField label="KRA PIN">
                    <v-text-field outlined dense v-model="props.business.pin" readonly class="text-field-background"
                        disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
        </v-row>
    </v-container></template>