<script setup>
import { computed, watchEffect } from "vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import KirinyagaPermitDetails from "./KirinyagaPermitDetails.vue";
import { permitValueNotFalsy } from "@/utils/functions";
import {
  countyIsKirinyaga,
  countyIsKilifi,
  usePermit,
} from "@/utils/vue_helpers";

const props = defineProps({
  permit: {
    type: Object,
    default: null,
  },
  isRenewal: {
    type: Boolean,
    default: true,
  },
  business: {
    type: Object,
    default: null,
  },
  latePenaltyAmount: {
    type: [Number, String],
    default: null,
  },
});

function permitHasFee(feeKeyOnPermit) {
  return (
    props.permit[feeKeyOnPermit] !== null &&
    props.permit[feeKeyOnPermit] !== null &&
    Number(props.permit[feeKeyOnPermit]) !== 0
  );
}

const { permitTrueFalseItems } = usePermit({
  permitRef: computed(() => props.permit),
  businessRef: computed(() => props.business),
  isRenewalRef: computed(() => props.isRenewal),
});

// const permitTrueFalseItems = computed(() => {
//     return {
//         'Paying for Sanitary Inspection': props.permit.sanitary_inspection,
//         'Paying for Food Hygiene License': props.permit.food_drugs_sale,
//         'Paying for Advertisement': props.permit.advertisement_fee,
//         'Paying for Solid Waste Management': props.permit.solid_waste_fee,
//         'Paying for Betting Control and Licensing': props.permit.betting_fee,
//     }
// })

const permitHasAdFees = computed(() => {
  return (
    props.permit.advertisement_fee === 1 ||
    props.permit.advertisement_fee === true
  );
});

function getSignboardDetails(permit) {
  return {
    "Signboard Type": permit.advert_signboard_type,
    "Signboard Size": permit.advert_signboard_size,
    "Signboard Unit of Measure": permit.advert_signboard_uom,
    "Signboard Number of Faces": permit.advert_signboard_no_of_faces,
  };
}

const signboardTypes = [
  { id: "Signboards", name: "Signboards" },
  {
    id: "Wall/Canopy Face (Illuminated Signboards)",
    name: "Wall/Canopy Face (Illuminated Signboards)",
  },
  {
    id: "Wall/Canopy Face (Non-illuminated Signboards)",
    name: "Wall/Canopy Face (Non-illuminated Signboards)",
  },
];

const signboardUomFeet = "Sq Feet";
const signboardUomMeters = "Sq Meters";
watchEffect(() => {
  const advertSignboardTypeIsSignboard =
    props.permit.advert_signboard_type === "Signboards";
  if (advertSignboardTypeIsSignboard) {
    props.permit.advert_signboard_uom = signboardUomFeet;
  } else {
    props.permit.advert_signboard_uom = signboardUomMeters;
  }
});

const yesNoSelectFieldItems = [
  {
    text: "Yes",
    value: 1,
  },
  {
    text: "No",
    value: 0,
  },
];

function yesNoSelectFieldComparator(selectFieldItemValue, modelValue) {
  const modelValueIs2 = Number(modelValue) === Number(2);
  const equivalentValueFor2 = 0;
  if (modelValueIs2) {
    modelValue = equivalentValueFor2;
  }
  return Number(selectFieldItemValue) === Number(modelValue);
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="auto">
        <v-tabs>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab class="text-none">Permit Details</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <template v-if="countyIsKirinyaga">
      <v-row no-gutters>
        <!-- <v-col cols="6">
                    <LabelledField class="flex-grow-1" label="Do you sell liquor?" required>
                        <v-select :items="yesNoSelectFieldItems" v-model="permit.liquor_sale"
                            :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                            dense class="text-field-background" disabled />
                    </LabelledField>
                </v-col>

                <v-col cols="6">
                    <LabelledField class="flex-grow-1" label="Do you pay for ownland?" required>
                        <v-select :items="yesNoSelectFieldItems" v-model="permit.ownland"
                            :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                            dense class="text-field-background" disabled />
                    </LabelledField>
                </v-col> -->

        <!-- <v-col cols="auto" md="6">
                    <v-simple-table>
                        <tbody>
                            <tr v-for="(value, title) in permitTrueFalseItems">
                                <td>
                                    <div>{{ title }}</div>
                                </td>
                                <td>
                                    <v-icon v-if="permitValueNotFalsy(value)" color="success">mdi-check-circle</v-icon>
                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col> -->

        <KirinyagaPermitDetails
          class="mt-4"
          :permit="permit"
          :business="business"
          :isRenewal="isRenewal"
          :latePenaltyAmount="latePenaltyAmount"
        />
      </v-row>
    </template>

    <template v-else-if="countyIsKilifi">
      <v-row>
        <!-- <v-col cols="6">
                                            <LabelledField class="flex-grow-1" label="Do you pay for sanitary inspection fee?" required>
                                                <v-select :items="yesNoSelectFieldItems" v-model="permit.sanitary_inspection"
                                                    :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                                                    dense class="text-field-background" disabled/>
                                            </LabelledField>
                                        </v-col>

                                        <v-col cols="6">
                                                <LabelledField class="flex-grow-1" label="Do you pay for food hygiene licence?" required>
                                                    <v-select :items="yesNoSelectFieldItems" v-model="permit.food_drugs_sale"
                                                        :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                                                        dense class="text-field-background" disabled/>
                                                </LabelledField>
                                            </v-col>

                                            <v-col cols="6">
                                                <LabelledField class="flex-grow-1" label="Do you pay for advertisement fee?" required>
                                                    <v-select :items="yesNoSelectFieldItems" v-model="permit.advertisement_fee"
                                                        :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                                                        dense class="text-field-background" disabled/>
                                                </LabelledField>
                                            </v-col>

                                            <template v-if="permitHasAdFees">
                                                <v-col cols="6">
                                                    <LabelledField class="flex-grow-1" label="Signboard Type" required>
                                                        <v-select :items="signboardTypes" v-model="permit.advert_signboard_type" item-value="id"
                                                            item-text="name" outlined dense class="text-field-background" disabled/>
                                                    </LabelledField>
                                                </v-col>
                                                <v-col cols="6">
                                                    <LabelledField class="flex-grow-1" label="Signboard Size" required>
                                                        <v-text-field class="text-field-background" outlined dense
                                                            v-model="permit.advert_signboard_size" type="number" min="1" disabled/>
                                                    </LabelledField>
                                                </v-col>
                                                <v-col cols="6">
                                                    <LabelledField class="flex-grow-1" label="Signboard Number of Faces" required>
                                                        <v-text-field class="text-field-background" outlined dense
                                                            v-model="permit.advert_signboard_no_of_faces" type="number" min="1" disabled/>
                                                    </LabelledField>
                                                </v-col>
                                                <v-col cols="6">
                                                    <LabelledField class="flex-grow-1" label="Signboard Unit of Measure" required>
                                                        <v-text-field class="text-field-background" outlined dense v-model="permit.advert_signboard_uom"
                                                            min="1" readonly disabled/>
                                                    </LabelledField>
                                                </v-col>
                                            </template>

                                            <v-col cols="6">
                                                <LabelledField class="flex-grow-1" label="Do you pay for solid waste management?" required>
                                                    <v-select :items="yesNoSelectFieldItems" v-model="permit.solid_waste_fee"
                                                        :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                                                        dense class="text-field-background" disabled/>
                                                </LabelledField>
                                            </v-col>

                                            <v-col cols="6">
                                                <LabelledField class="flex-grow-1" label="Do you pay for betting control and licensing?" required>
                                                    <v-select :items="yesNoSelectFieldItems" v-model="permit.betting_fee"
                                                        :value-comparator="yesNoSelectFieldComparator" item-value="value" item-text="text" outlined
                                                        dense class="text-field-background" disabled/>
                                                </LabelledField>
                                            </v-col> -->

        <!-- Permit items as table -->
        <v-col cols="auto">
          <v-simple-table>
            <tbody>
              <tr v-for="(value, title) in permitTrueFalseItems">
                <td>
                  <div>{{ title }}</div>
                </td>
                <td>
                  <v-icon v-if="permitValueNotFalsy(value)" color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-if="
                          permitHasAdFees &&
                          title === 'Paying for Advertisement'
                        "
                        class="ml-2"
                        v-on="on"
                        v-bind="attrs"
                      >
                        mdi-information
                      </v-icon>
                    </template>

                    <div class="white rounded pa-2 mx-n3">
                      <div
                        v-for="(value, title) in getSignboardDetails(
                          props.permit
                        )"
                        class="black--text"
                      >
                        {{ `${title}: ${value}` }}
                      </div>
                    </div>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
