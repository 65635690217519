<script setup>
import LoadingArea from '@/components/utils/LoadingArea.vue';
import { useViewBusinessApprovers } from './view_business_business_logic'
import { computed } from 'vue'

const props = defineProps({
    business: {
        type: Object,
        default: null
    }
})

const {
    approversData
} = useViewBusinessApprovers(computed(() => props.business))
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="auto">
                <v-tabs>
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Approval Details</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <LoadingArea :loading="approversData.fetchingApprovers.value">
                    <v-simple-table>
                        <template #default>
                            <thead>
                                <tr>
                                    <th class="text-left">Type</th>
                                    <th class="text-left">Role</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-left">Action Date</th>
                                    <th class="text-left">Comment</th>
                                </tr>
                            </thead>

                            <tbody>
                                <template v-if="approversData.approversNotEmpty.value">
                                    <tr v-for="approver in approversData.allApprovers.value">
                                        <td>
                                            {{ approver.type }}
                                        </td>
                                        <td>
                                            {{ approver.role }}
                                        </td>
                                        <td>
                                            {{ approver.action }}
                                        </td>
                                        <td>
                                            {{ approver.actioned }}
                                        </td>
                                        <td>
                                            {{ approver.comment }}
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td align="center" colspan="5">
                                            {{ approversData.fetchingApprovers.value ? 'Loading' : 'No Approvers' }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </template>
                    </v-simple-table>
                </LoadingArea>
            </v-col>
        </v-row>
    </v-container>
</template>