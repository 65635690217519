<script setup>
import ActionsContainer from "../utils/ActionsContainer.vue";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";
import {
  arrayNotEmpty,
  getFormattedBillDate,
  getFormattedMoney,
} from "@/utils/functions";
import { useRouter } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import LoadingArea from "../utils/LoadingArea.vue";
import Table from "@/components/utils/Table.vue";
import { computed } from "vue";

const props = defineProps({
  businessId: {
    type: [String, Number],
    default: null,
  },
  fetchedBillsAndReceipts: {
    type: Array,
    default: null,
  },
  fetchingBillsAndReceipts: {
    type: Boolean,
    default: false,
  },
});

function isBill(billOrReceipt) {
  return billOrReceipt.type === "Bill";
}

const { router } = useRouter();
function onPayBillClicked(bill) {
  const paymentRoute = routes.payment.withQuery({
    billNumbersToPayAsString: bill.bill_number,
    directPayment: true,
    billsToPay: [bill],
  });
  router.value.push(paymentRoute);
}

const hasAtLeastOneBillToPay = computed(() => {
  for (const billReceipt of props.fetchedBillsAndReceipts) {
    if (isBill(billReceipt) && billReceipt.status === "Unpaid") {
      return true;
    }
  }
  return false;
});

const tableHeaders = computed(() => {
  const headers = [
    { text: "Type", value: "type" },
    { text: "Number", value: "number" },
    { text: "Date", value: "date" },
    { text: "Amount", value: "amount" },
    { text: "Status", value: "status" },
    { text: "Download", value: "download", action: true },
  ];
  if (hasAtLeastOneBillToPay.value) {
    headers.push({ text: "Pay", value: "pay", action: true });
  }
  return headers;
});
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="auto">
        <v-tabs>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab class="text-none">Bills And Receipts</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :style="{ position: 'relative' }">
        <LoadingArea :loading="fetchingBillsAndReceipts">
          <Table
            :headers="tableHeaders"
            :items="fetchedBillsAndReceipts"
            :loading="fetchingBillsAndReceipts"
            emptyText="No Bills Found"
          >
            <template #item.date="{ item: billReceipt }">
              {{ getFormattedBillDate(billReceipt.date) }}
            </template>

            <template #item.amount="{ item: billReceipt }">
              {{ getFormattedMoney(billReceipt.amount) }}
            </template>

            <template #item.status="{ item: billReceipt }">
              <span
                :class="{
                  'font-weight-bold': true,
                  'green--text': billReceipt.status === 'Paid',
                  'red--text': billReceipt.status === 'Unpaid',
                }"
                >{{ billReceipt.status }}</span
              >
            </template>

            <template #item.download="{ item: billReceipt }">
              <ActionButton
                v-if="isBill(billReceipt)"
                :action="actions.downloadBill"
                inTable
                @click="$emit('printBillClicked', billReceipt)"
              />
              <ActionButton
                v-else
                :action="actions.downloadReceipt"
                inTable
                @click="$emit('printReceiptClicked', billReceipt)"
              />
            </template>

            <template v-if="hasAtLeastOneBillToPay" #item.pay="{ item: billReceipt }">
              <ActionButton
                v-if="isBill(billReceipt) && billReceipt.status === 'Unpaid'"
                :action="actions.pay"
                inTable
                @click="onPayBillClicked(billReceipt)"
              />
              <span v-else></span>
            </template>
          </Table>

          <v-simple-table v-if="false">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Type</th>
                  <th class="text-left">Number</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Status</th>
                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="arrayNotEmpty(fetchedBillsAndReceipts)">
                  <template v-for="billReceipt in fetchedBillsAndReceipts">
                    <!-- Bill -->
                    <v-tooltip v-if="isBill(billReceipt)" bottom>
                      <template #activator="{ on, attrs }">
                        <tr v-on="on" v-bind="attrs">
                          <td>{{ billReceipt.type }}</td>
                          <td>{{ billReceipt.number }}</td>
                          <td>{{ getFormattedBillDate(billReceipt.date) }}</td>
                          <td>{{ getFormattedMoney(billReceipt.amount) }}</td>
                          <td
                            :class="{
                              'font-weight-bold': true,
                              'green--text': billReceipt.status === 'Paid',
                              'red--text': billReceipt.status === 'Unpaid',
                            }"
                          >
                            {{ billReceipt.status }}
                          </td>
                          <td align="right">
                            <ActionsContainer>
                              <ActionButton
                                :action="actions.downloadBill"
                                inTable
                                @click="$emit('printBillClicked', billReceipt)"
                              />
                              <ActionButton
                                v-if="billReceipt.status === 'Unpaid'"
                                :action="actions.pay"
                                inTable
                                @click="onPayBillClicked(billReceipt)"
                              />
                            </ActionsContainer>
                          </td>
                        </tr>
                      </template>

                      <div>
                        <span class="mb-2">Bill Items</span>

                        <v-simple-table dense class="background mb-2">
                          <template #default>
                            <tbody>
                              <tr v-for="billItem in billReceipt.bill_items">
                                <td>
                                  {{ billItem.fee_name }}
                                </td>
                                <td>
                                  {{ getFormattedMoney(billItem.sub_total) }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </v-tooltip>

                    <!-- Receipt -->
                    <tr v-else>
                      <td>{{ billReceipt.type }}</td>
                      <td>{{ billReceipt.number }}</td>
                      <td>{{ getFormattedBillDate(billReceipt.date) }}</td>
                      <td>{{ getFormattedMoney(billReceipt.amount) }}</td>
                      <td
                        :class="{
                          'font-weight-bold': true,
                          'green--text': billReceipt.status === 'Paid',
                          'red--text': billReceipt.status === 'Unpaid',
                        }"
                      >
                        {{ billReceipt.status }}
                      </td>
                      <td align="right">
                        <ActionsContainer>
                          <ActionButton
                            :action="actions.downloadReceipt"
                            inTable
                            @click="$emit('printReceiptClicked', billReceipt)"
                          />
                        </ActionsContainer>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <td colspan="6" align="center">
                    <div class="my-4">
                      {{
                        fetchingBillsAndReceipts
                          ? "Loading"
                          : "No bills or receipts"
                      }}
                    </div>
                  </td>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </LoadingArea>

        <!-- <v-overlay v-if="fetchingBillsAndReceipts" absolute opacity="0.5" color="white" /> -->
      </v-col>
    </v-row>
  </v-container>
</template>
