import { reactive, watch, computed, onMounted, ref, watchEffect } from 'vue'
import { requestStatus } from '@/utils/constants'
import {
    stringNotEmpty, debounce, getLiquorLicenseForBusiness,
    getPermitForBusiness, arrayNotEmpty
} from '@/utils/functions'
import { userProfile, countyIsKirinyaga } from '@/utils/vue_helpers'
import apiCall from '@/utils/api.js'
import { useQuery } from '@tanstack/vue-query'

export function useViewBusiness() {
    const loadingCauses = {
        SEARCH_CHANGED: 'SEARCH_CHANGED',
        PAGINATION_CHANGED: 'PAGINATION_CHANGED'
    }

    const viewBusinessData = reactive({
        fetchedBusinesses: [],
        paginationData: {
            currentPage: 1,
            perPage: 0,
            total: 0,
            visible: 10
        },
        fetchBusinessesRequestStatus: requestStatus.NOT_SENDING,
        setSearchUiToLoading: false,
        searchModel: ''
    })

    const paginationComponentLength = computed(() => {
        const numberOfFees = viewBusinessData.paginationData.total
        const itemsPerPage = viewBusinessData.paginationData.perPage
        const numberOfFeesIsValid = numberOfFees !== 0
        if (numberOfFeesIsValid) {
            return Math.ceil(numberOfFees / itemsPerPage)
        }
        return null
    })

    const fetchingBusinesses = computed(() => {
        return viewBusinessData.fetchBusinessesRequestStatus === requestStatus.SENDING
    })

    const fetchedBusinessesNotEmpty = computed(() => {
        const fetchedBusinesses = viewBusinessData.fetchedBusinesses
        return fetchBusinesses !== null && fetchedBusinesses.length > 0
    })

    const debouncedFetchBusinesses = debounce(fetchBusinesses, 1000)
    const getSearchModel = computed(() => {
        return viewBusinessData.searchModel
    })
    watch(getSearchModel, (newVal) => {
        const searchModelNotEmpty = stringNotEmpty(newVal)
        if (searchModelNotEmpty) {
            viewBusinessData.setSearchUiToLoading = true
        }
        debouncedFetchBusinesses()
    })

    function setPaginationData(serverResponse) {
        viewBusinessData.paginationData.currentPage = serverResponse.current_page
        viewBusinessData.paginationData.total = serverResponse.total
        viewBusinessData.paginationData.perPage = serverResponse.per_page
    }

    async function fetchBusinesses() {
        const userIdNumber = userProfile.value.id_number
        const currentPage = viewBusinessData.paginationData.currentPage
        let url = `/api/client/business?client-id-number=${userIdNumber}&page=${currentPage}`
        const searchFieldValue = viewBusinessData.searchModel
        const searchFieldNotEmpty = stringNotEmpty(searchFieldValue)
        if (searchFieldNotEmpty) {
            url = url + `&type=search&search=${searchFieldValue}`
        }
        try {
            viewBusinessData.fetchBusinessesRequestStatus = requestStatus.SENDING
            const response = await apiCall({
                url,
                method: 'GET'
            })
            viewBusinessData.fetchedBusinesses = response.data
            setPaginationData(response)
            viewBusinessData.fetchBusinessesRequestStatus = requestStatus.COMPLETE
        } catch (error) {
            console.log(error)
            viewBusinessData.fetchBusinessesRequestStatus = requestStatus.ERROR
        } finally {
            viewBusinessData.setSearchUiToLoading = false
        }
    }

    onMounted(async () => {
        await fetchBusinesses()
    })

    return { viewBusinessData, fetchBusinesses, fetchingBusinesses, fetchedBusinessesNotEmpty, paginationComponentLength }
}

export function useViewBusinessApprovers(business) {
    const applicationTypes = {
        sbp: 'SBP',
        liquor: 'Liquor'
    }

    const approvers = reactive({
        sbpApprovers: null,
        liquorApprovers: null
    })

    function clearApprovers() {
        approvers.sbpApprovers = null
        approvers.liquorApprovers = null
    }

    const approversData = {
        sbpApproversNotEmpty: computed(() => {
            return arrayNotEmpty(approvers.sbpApprovers)
        }),
        liquorApproversNotEmpty: computed(() => {
            return arrayNotEmpty(approvers.liquorApprovers)
        }),
        approversNotEmpty: computed(() => {
            return arrayNotEmpty(approvers.sbpApprovers) || arrayNotEmpty(approvers.liquorApprovers)
        }),
        fetchingApprovers: computed(() => {
            return fetchApproversRequestStatus.value === requestStatus.SENDING
        }),
        allApprovers: computed(() => {
            let allApprovers = []
            if (!!approvers.liquorApprovers) {
                allApprovers = [...allApprovers, ...approvers.liquorApprovers]
            }
            if (!!approvers.sbpApprovers) {
                allApprovers = [...approvers.sbpApprovers]
            }
            return allApprovers
        })
    }

    const fetchApproversRequestStatus = ref(requestStatus.NOT_SENDING)

    watchEffect(async () => {
        clearApprovers()

        if (countyIsKirinyaga.value) {
            const businessLiquorLicense = getLiquorLicenseForBusiness(business.value)
            const businessLiquorLicenseNotEmpty = !!businessLiquorLicense
            if (businessLiquorLicenseNotEmpty) {
                await fetchApprovers({
                    applicationType: applicationTypes.liquor,
                    application: businessLiquorLicense
                })
            }
        }

        const businessPermit = getPermitForBusiness(business.value)
        const businessPermitNotEmpty = !!businessPermit
        if (businessPermitNotEmpty) {
            await fetchApprovers({
                applicationType: applicationTypes.sbp,
                application: businessPermit
            })
        }
    })

    async function fetchApprovers({ applicationType, application }) {
        const requestData = {
            application_type: applicationType,
            application: JSON.stringify(application)
        }

        try {
            fetchApproversRequestStatus.value = requestStatus.SENDING
            let receivedApprovers = await apiCall({
                url: '/api/client/get-workflow-approvers',
                data: requestData,
                method: 'POST'
            })
            if (responseHasNoApprovers(receivedApprovers)) {
                receivedApprovers = []
            }
            setApproversBasedOnApplicationType(applicationType, receivedApprovers)
            fetchApproversRequestStatus.value = requestStatus.COMPLETE
        } catch (error) {
            fetchApproversRequestStatus.value = requestStatus.ERROR
            throw error
        }
    }

    // If approvers on appropriate type eg sbp approvers on sbp type
    function setApproversBasedOnApplicationType(applicationType, receivedApprovers) {
        setApplicationTypeOnApprovers(applicationType, receivedApprovers)

        if (applicationType === applicationTypes.sbp) {
            approvers.sbpApprovers = receivedApprovers
        } else if (applicationType === applicationTypes.liquor) {
            approvers.liquorApprovers = receivedApprovers
        }
    }

    function setApplicationTypeOnApprovers(applicationType, approvers) {
        approvers.forEach(approver => {
            approver.type = applicationType
        })
    }

    function responseHasNoApprovers(response) {
        const noApproversMessage = 'No Workflow Action Logs found for this application'
        const responseStatusIsError = response?.status === 'error' ?? false
        const responseMsgIsNoApprovers = response?.message === noApproversMessage ?? false
        const responseHasNoApprovers = responseStatusIsError && responseMsgIsNoApprovers
        if (responseHasNoApprovers) {
            return true
        }
        return false
    }

    return {
        approvers, approversData
    }
}

export function useFetchBusinessBillsAndReceipts({ businessIdRef }) {
    async function fetchBillsAndReceipts({ queryKey }) {
        const [_, { businessId: idForBusiness }] = queryKey
        const url = `/api/client/business/business-bills?business_id=${idForBusiness}`
        try {
            const response = await apiCall({
                url,
                method: 'GET'
            })
            return response
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    const businessBillsAndReceipts = useQuery({
        queryKey: ['businessBillsAndReceipts', { businessId: businessIdRef }],
        queryFn: fetchBillsAndReceipts,
        refetchOnWindowFocus: false,
        refetchOnMount: true
    })

    return { businessBillsAndReceipts }
}

export function useGetBusinessPayingForFromBills({ billsRef = null }) {
    const businessPayingFor = computed(() => {
        const payingFor = {}
        billsRef.value?.forEach(bill => {
            const billItems = bill.bill_items
            billItems.forEach((billItem) => {
                payingFor[billItem.fee_name] = true
            })
        })
        return Object.keys(payingFor)
    })
    return { businessPayingFor }
}
