<script setup>
import { computed, ref } from "vue";
import ViewBusinessPermits from "./ViewBusinessPermits.vue";
import ViewBusinessBills from "./ViewBusinessBills.vue";
import ViewBusinessApprovers from "./ViewBusinessApprovers.vue";
import ViewGeneratedDocuments from "./ViewGeneratedDocuments.vue";
import ViewPdf from "@/components/utils/ViewPdf";
import {
  useDownloadFile,
  usePermit,
  countyIsKirinyaga,
  countyIsKilifi,
} from "@/utils/vue_helpers";
import { getPermitForBusiness } from "@/utils/functions";
import {
  useFetchBusinessBillsAndReceipts,
  useGetBusinessPayingForFromBills,
} from "./view_business_business_logic";

const props = defineProps({
  business: {
    type: Object,
    default: null,
  },
  showGoBack: {
    type: Boolean,
    default: false,
  },
});

const { businessBillsAndReceipts } = useFetchBusinessBillsAndReceipts({
  businessIdRef: computed(() => props.business.id),
});

const businessBills = computed(() => {
  return (
    businessBillsAndReceipts.data.value?.filter(
      (billOrReceipt) => billOrReceipt.type === "Bill"
    ) ?? null
  );
});

const { businessPayingFor } = useGetBusinessPayingForFromBills({
  billsRef: businessBills,
});

const { permitTrueFalseItems } = usePermit({
  permitRef: computed(() => getPermitForBusiness(props.business)),
  businessRef: computed(() => props.business),
  isRenewalRef: computed(() => true),
});

const businessNotEmpty = computed(() => {
  return props.business !== null;
});

const businessDetails = computed(() => {
  let businessDetails = {};
  if (businessNotEmpty.value) {
    businessDetails = getBusinessDetails(props.business);
  }
  return businessDetails;
});

function getBusinessDetails(business) {
  const businessDetails = {
    section1: {},
    section2: {},
  };
  businessDetails.section1["Business Name"] = business.business_name;
  businessDetails.section1["Business Identification Number"] =
    business.identification_no;
  businessDetails.section1["Business Code"] = business.trade_license_code;
  businessDetails.section1["Business Type"] = business.business_type;

  const businessActivity = business.business_activity;
  const businessActivityNotEmpty = !!businessActivity;
  if (businessActivityNotEmpty) {
    businessDetails.section1["Business Activity"] =
      businessActivity.activity_name;
  }

  const zone = business.zone;
  const zoneNotEmpty = zone !== null;
  if (zoneNotEmpty) {
    businessDetails.section1["Zone"] = zone.zone_name;
  }

  businessDetails.section1["Subcounty"] = business.sub_county.sub_county_name;
  businessDetails.section1["Ward"] = business.ward.ward_name;
  businessDetails.section1["Town"] = business.town.town_name;
  businessDetails.section2["Physical Address"] =
    business.business_physical_address;
  businessDetails.section2["Plot Number"] = business.plot_no;
  // businessDetails.section2['Status'] = business?.work_flow_status?.status_name ?? 'Unspecified'
  // businessDetails.section2['Postal Address'] = `${business.postal_address} - ${business.postal_code}`
  // businessDetails.section2['Postal Town'] = business.postal_town

  return businessDetails;
}

const currentBusinessPermit = computed(() => {
  const currentBusinessPermitIndex = props.business.business_permits.length - 1;
  return props.business.business_permits[currentBusinessPermitIndex];
});
const hasPreviousBusinessPermits = computed(() => {
  const hasMoreThanOneBusinessPermit =
    props.business.business_permits.length > 1;
  return hasMoreThanOneBusinessPermit;
});
const previousBusinessPermits = computed(() => {
  let previousPermits = null;
  if (hasPreviousBusinessPermits.value) {
    const currentBusinessPermitIndex =
      props.business.business_permits.length - 1;
    previousPermits = props.business.business_permits.slice(
      0,
      currentBusinessPermitIndex
    );
  }
  return previousPermits;
});

const billIdToView = ref(null);

const { downloadFile } = useDownloadFile();

function onPrintBillClicked(bill) {
  downloadFile({ billId: bill.id, billNumber: bill.number });
  // billIdToView.value = bill.id
  // showPdf.value = true
}

const receiptNumberToView = ref(null);
function onPrintReceiptClicked(receipt) {
  downloadFile({ receiptNumber: receipt.number });
  // receiptNumberToView.value = receipt.number
  // showPdf.value = true
}

const showPdf = ref(false);
function closePdfDialog() {
  showPdf.value = false;
  billIdToView.value = null;
  receiptNumberToView.value = null;
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="1">
        <v-btn plain @click="$emit('goBackClicked')">
          <v-icon left class="">mdi-arrow-left</v-icon>
          <span class="text-caption text-none">Back to Businesses</span>
        </v-btn>
      </v-col>
      <v-col cols="10">
        <div class="text-h6 text-center font-weight-bold primary--text">
          Business Details
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row justify-md="center">
      <v-col cols="5">
        <v-simple-table class="business-details-table">
          <tbody>
            <tr
              v-for="(
                businessAttribute, attributeTitle
              ) in businessDetails.section1"
            >
              <td>
                <div
                  class="pr-2"
                  :style="{
                    //borderRight: 'thin solid rgba(0, 0, 0, 0.12)'
                  }"
                >
                  {{ attributeTitle }}
                </div>
              </td>
              <td>
                {{ businessAttribute }}
              </td>
            </tr>

            <tr
              v-for="(
                businessAttribute, attributeTitle
              ) in businessDetails.section2"
            >
              <td>
                <div class="pr-2" :style="{}">
                  {{ attributeTitle }}
                </div>
              </td>
              <td>
                {{ businessAttribute }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>

      <v-col cols="1" class="d-flex justify-center">
        <v-divider vertical />
      </v-col>

      <v-col cols="5">
        <v-simple-table class="business-details-table">
          <tbody>
            <template v-if="countyIsKilifi">
              <tr v-for="(value, title) in permitTrueFalseItems" :key="title">
                <td>
                  {{ title }}
                </td>
                <td>
                  <v-icon v-if="value === true" color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                </td>
              </tr>
            </template>
            <template v-else-if="countyIsKirinyaga">
              <template v-if="!businessBillsAndReceipts.isFetching.value">
                <tr v-for="(feeName, index) in businessPayingFor" :key="index">
                  <td>
                    {{ `Paying for ${feeName}` }}
                  </td>
                  <td>
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="num in 5" :index="num">
                  <td>
                    <v-skeleton-loader type="text" />
                  </td>
                  <td>
                    <v-skeleton-loader type="text" />
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ViewBusinessPermits
          :currentPermit="currentBusinessPermit"
          :previousPermits="previousBusinessPermits"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ViewBusinessBills
          :businessId="props.business.id"
          :fetchedBillsAndReceipts="businessBillsAndReceipts.data.value"
          :fetchingBillsAndReceipts="businessBillsAndReceipts.isFetching.value"
          @printBillClicked="onPrintBillClicked"
          @printReceiptClicked="onPrintReceiptClicked"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ViewBusinessApprovers :business="props.business" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ViewGeneratedDocuments
          :business="props.business"
          :permit="currentBusinessPermit"
        />
      </v-col>
    </v-row>

    <ViewPdf
      v-if="!!billIdToView || !!receiptNumberToView"
      :billId="billIdToView"
      :receiptNumber="receiptNumberToView"
      :open="showPdf"
      @update:open="closePdfDialog"
    />
  </v-container>
</template>

<style scoped lang="scss">
// :deep(.business-details-table td) {
//     border-bottom: none !important;
//     position: relative;

//     &::after {
//         content: "";
//         background: black;
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         height: 1px;
//     }
// }
</style>
