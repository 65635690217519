<script setup>
import { businessFormData, businessFormDataNotEmpty } from '@/utils/vue_helpers'
import LabelledField from '@/components/utils/LabelledField.vue'
import { countyIsKilifi, countyIsKirinyaga } from '@/utils/vue_helpers'
import apiCall from '@/utils/api'
import { requestStatus } from '@/utils/constants'
import { computed, watchEffect, ref } from 'vue';

const props = defineProps({
    business: {
        type: Object,
        default: null
    }
})

const wards = computed(() => {
    let filteredWards = []
    const subcounties = businessFormData.value.subCounty
    const selectedSubcountyId = props.business.sub_county_id
    const subcountyMatchingId = subcounties.find(subcounty => subcounty.id === selectedSubcountyId)
    const subcountyMatchingIdNotEmpty = subcountyMatchingId !== null && subcountyMatchingId !== undefined
    if (subcountyMatchingIdNotEmpty) {
        filteredWards = subcountyMatchingId.wards
    }
    return filteredWards
})

const towns = computed(() => {
    let filteredTowns = []
    const selectedWardId = props.business.ward_id
    const selectedWard = wards.value.find(ward => ward.id === selectedWardId)
    const selectedWardNotEmpty = selectedWard !== null && selectedWard !== undefined
    if (selectedWardNotEmpty) {
        filteredTowns = selectedWard.towns
    }
    return filteredTowns
})

const tradeLicenseCode = computed(() => {
    return props.business.trade_license_code
})
const tradeLicenseCodeNotEmpty = computed(() => {
    return tradeLicenseCode.value !== null && tradeLicenseCode.value !== undefined
})

const zoneId = computed(() => {
    return props.business.zone_id
})
const zoneIdNotEmpty = computed(() => {
    return zoneId.value !== null && zoneId.value !== undefined
})

const showSelectFeeField = computed(() => {
    const requiredTradeLicenseCodes = [540, 543, 546]
    const tradeLicenseCodeMatchesRequiredCode = requiredTradeLicenseCodes.find(
        requiredCode => requiredCode === Number(tradeLicenseCode.value)) !== undefined
    return tradeLicenseCodeMatchesRequiredCode && countyIsKilifi
})

const selectFeeFieldItems = ref([])
const fetchSelectFeeFieldItemsRequestStatus = ref(requestStatus.NOT_SENT)
const fetchingSelectFeeFieldItems = computed(() => {5
    return fetchSelectFeeFieldItemsRequestStatus.value === requestStatus.SENDING
})
watchEffect(async () => {
    if (tradeLicenseCodeNotEmpty.value && zoneIdNotEmpty.value) {
        const url = `/api/client/business/get-fee-code-types?fee_code=${tradeLicenseCode.value}&zone_id=${zoneId.value}`
        try {
            fetchSelectFeeFieldItemsRequestStatus.value = requestStatus.SENDING
            const response = await apiCall({ url, method: 'GET' })
            selectFeeFieldItems.value = response
            fetchSelectFeeFieldItemsRequestStatus.value = requestStatus.COMPLETE
        } catch (error) {
            fetchSelectFeeFieldItemsRequestStatus.value = requestStatus.ERROR
            console.log(error)
        }
    }
})
// async function onZoneFieldChange() {
//     const zoneId = props.business.zone_id
//     const url = `/api/client/business/get-fee-code-types?fee_code=${tradeLicenseCode.value}&zone_id=${zoneId}`
//     try {
//         fetchSelectedFeeFieldItemsRequestStatus.value = requestStatus.SENDING
//         const response = apiCall({ url, method: 'GET' })
//         selectedFeeFieldItems.value = response
//         fetchSelectedFeeFieldItemsRequestStatus.value = requestStatus.COMPLETE
//     } catch (error) {
//         fetchSelectedFeeFieldItemsRequestStatus.value = requestStatus.ERROR
//         console.log(error)
//     }
// }

</script>

<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12">
                <!-- <div class="primary--text text-decoration-underline font-weight-bold text-h6">Business Location Details</div> -->
                <v-tabs>
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Business Location Details</v-tab>
                </v-tabs>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <LabelledField label="Subcounty" required>
                    <v-select :items="businessFormData.subCounty" v-model="business.sub_county_id" item-value="id"
                        item-text="sub_county_name" outlined dense class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
            <v-col cols="6">
                <LabelledField label="Ward" required>
                    <v-select :items="wards" v-model="business.ward_id" item-value="id" item-text="ward_name" outlined dense
                        class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="!countyIsKilifi" cols="6">
                <LabelledField label="Town" required>
                    <v-select :items="towns" v-model="business.town_id" item-value="id" item-text="town_name" outlined dense
                        class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>

            <v-col cols="6">
                <LabelledField label="Zone" required>
                    <v-select :items="businessFormData.Zone" v-model="business.zone_id" item-value="id"
                        item-text="zone_name" outlined dense class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>

            <v-col v-if="showSelectFeeField && countyIsKilifi" cols="6">
                    <LabelledField class="flex-grow-1" label="Select Fee" required>
                        <v-select :items="selectFeeFieldItems" v-model="business.selected_fee" item-value="id"
                            item-text="fee_type" outlined :disabled="fetchingSelectFeeFieldItems" dense class="text-field-background" disabled hide-details="auto"/>
                    </LabelledField>
                    <v-progress-linear v-if="fetchingSelectFeeFieldItems" class="mt-2" indeterminate />
            </v-col>

            <v-col cols="6">
                <LabelledField label="Plot Number">
                    <v-text-field outlined dense v-model="business.plot_no" readonly class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>

            <v-col cols="6">
                <LabelledField label="Physical Address" required>
                    <v-text-field outlined dense v-model="business.business_physical_address" readonly
                        class="text-field-background" disabled hide-details="auto"/>
                </LabelledField>
            </v-col>
        </v-row>
    </v-container>
</template>