<script setup>
import { ref, computed } from 'vue'
import LabelledField from "@/components/utils/LabelledField.vue";

const props = defineProps({
    currentPermit: {
        type: Object,
        default: null
    },
    previousPermits: {
        type: Array,
        default: null
    }
})

const tabs = {
    CURRENT_PERMIT: 0,
    PREVIOUS_PERMITS: 1
}
const currentTab = ref(tabs.CURRENT_PERMIT)

const hasPreviousPermits = computed(() => {
    return props.previousPermits !== null && props.previousPermits.length > 0
})
const numberOfPreviousPermits = computed(() => {
    if (hasPreviousPermits.value) {
        return props.previousPermits.length
    }
    return 0
})

const currentPermitApproved = computed(() => {
    return props.currentPermit.permit_status === 'Approved'
})
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row dense>
            <v-col cols="auto">
                <v-tabs>
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Business Permits</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs v-model="currentTab">
                    <v-tabs-slider color="primary" />

                    <v-tab class="text-none">
                        Current Permit
                    </v-tab>
                    <v-tab class="text-none">
                        Previous Permits {{ hasPreviousPermits ? `(${numberOfPreviousPermits})` : '' }}
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs-items v-model="currentTab">
                    <v-tab-item class="mt-1">
                        <v-container fluid class="pa-0">
                            <v-row>
                                <v-col cols="6">
                                    <LabelledField label="SBP Number">
                                        <v-text-field dense outlined readonly :value="currentPermit.sbp_number" disabled
                                            class="text-field-background" />
                                    </LabelledField>
                                </v-col>
                                <v-col cols="6">
                                    <LabelledField label="Status">
                                        <v-text-field dense outlined readonly :value="currentPermit.permit_status" disabled
                                            class="text-field-background" />
                                    </LabelledField>
                                </v-col>
                            </v-row>
                            <v-row v-if="currentPermitApproved">
                                <v-col cols="6">
                                    <LabelledField label="Valid From">
                                        <v-text-field dense outlined readonly :value="currentPermit.validity_from" disabled
                                            class="text-field-background" />
                                    </LabelledField>
                                </v-col>
                                <v-col cols="6">
                                    <LabelledField label="Valid To">
                                        <v-text-field dense outlined readonly disabled
                                            :value="currentPermit.validity_to" class="text-field-background" />
                                    </LabelledField>
                                </v-col>
                            </v-row>
                            <div>

                            </div>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item>
                        <v-simple-table>
                            <template #default>
                                <thead>
                                    <tr class="primary white--text">
                                        <th class="text-left  white--text">Status</th>
                                        <th class="text-left  white--text">Valid From</th>
                                        <th class="text-left  white--text">Valid To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="hasPreviousPermits">
                                        <tr v-for="previousPermit in props.previousPermits">
                                            <td>
                                                {{ previousPermit.permit_status }}
                                            </td>
                                            <td>
                                                {{ previousPermit.validity_from }}
                                            </td>
                                            <td>
                                                {{ previousPermit.validity_to }}
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td colspan="4" class="text-center">
                                            No Previous Permits
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>