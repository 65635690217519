<script setup>
import { computed } from "vue";
import { businessFormData } from "@/utils/vue_helpers";
import {
  checkIsLiquorPermit,
  checkKiriBusinessExemptFromCertainFees,
  permitValueNotFalsy,
  getSbpApplicationFee,
} from "@/utils/functions";

const props = defineProps({
  permit: {
    type: Object,
    default: null,
  },
  business: {
    type: Object,
    default: null,
  },
  isRenewal: {
    type: Boolean,
    default: true,
  },
  latePenaltyAmount: {
    type: [Number, String],
    default: null,
  },
});

const isLiquorPermit = computed(() => {
  return checkIsLiquorPermit(props.permit);
});

const isExemptFromCertainFees = computed(() => {
  return checkKiriBusinessExemptFromCertainFees(props.business);
});

const isPayingMandatoryFees = computed(() => {
  return !isExemptFromCertainFees.value;
});

const permitItems = computed(() => {
  let items = {};

  const applicationFee = getSbpApplicationFee(
    props.business,
    businessFormData.value
  );
  const applicationFeeNotEmpty = !!applicationFee;
  if (applicationFeeNotEmpty) {
    const applicationFeeTitle = `${applicationFee?.fee_name} Fee`;
    items = {
      [applicationFeeTitle]: true,
    };
  }

  if (props.isRenewal) {
    items = {
      ...items,
      "Paying for Renewal Fee": true,
      "Paying for Sublet Fee": false,
      "Paying for Late Penalty": !!props.latePenaltyAmount,
    };
  } else {
    items = {
      ...items,
      "Paying for Registration Fee": true,
      "Paying for Sublet Fee": permitValueNotFalsy(props.permit.sublet),
    };
  }

  if (isLiquorPermit.value) {
    items = {
      ...items,
      "Paying for Liquor License": true,
    };
  } else {
    items = {
      ...items,
      "Paying for Liquor Application": false,
    };
  }

  items = {
    ...items,
    "Paying for Public Health Certificate": permitValueNotFalsy(
      props.permit.public_health_certificate
    ),
    "Paying for Public Health Inspections": permitValueNotFalsy(
      props.permit.public_health_inspection
    ),
    "Paying for Conservancy Fee": isPayingMandatoryFees.value,
    "Paying for Advertisement Fee": isPayingMandatoryFees.value,
    "Paying for Ownland Fee": permitValueNotFalsy(props.permit.ownland),
  };

  return items;
});
</script>

<template>
  <v-container fluid class="pa-0">
    <template v-if="false /*isLiquorPermit*/">
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text v-if="false" class="py-2">
              Step 1 (Current Step)
            </v-card-text>
            <v-card-text class="py-0">
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>Paying for Liquor Application</td>
                    <td align="right">
                      <v-icon color="success">mdi-check-circle</v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="false">
        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text class="py-2"> Step 2 (After Approval) </v-card-text>
            <v-card-text class="py-0">
              <v-simple-table>
                <tbody>
                  <tr v-for="(value, title) in permitItems">
                    <td>
                      <div>{{ title }}</div>
                    </td>
                    <td align="right">
                      <v-icon v-if="value === true" color="success"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon v-else color="error">mdi-close-circle</v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-row v-if="isLiquorPermit" no-gutters>
      <v-col cols="12" md="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td>Paying for Liquor Application</td>
              <td align="right">
                <v-icon color="success">mdi-check-circle</v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <template v-else>
      <v-row>
        <v-col cols="auto">
          <v-simple-table>
            <tbody>
              <tr v-for="(value, title) in permitItems">
                <td>
                  <div>{{ title }}</div>
                </td>
                <td align="right">
                  <v-icon v-if="value === true" color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
