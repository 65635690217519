import apiCall from "@/utils/api";
import { useQuery } from "@tanstack/vue-query";

export function useGetLatePenalty({ sbpNumberRef, permitValidToRef, isNotLiquorPermitRef }) {
    async function getLatePenalty({ queryKey }) {
        console.log(queryKey)
        const [sbpNumber] = queryKey
        try {
            const response = await apiCall({
                url: `/api/client/business/get-late-sbp-penalty?sbp_number=${sbpNumber}`,
                method: 'GET'
            })
            const penaltyAmount = response.amount
            return penaltyAmount
        } catch (error) {
            throw (error)
        }
    }

    const latePenalty = useQuery({
        queryKey: [sbpNumberRef, permitValidToRef],
        queryFn: getLatePenalty,
        refetchOnWindowFocus: false,
        // enabled: isNotLiquorPermitRef
    })

    return latePenalty
}