<script setup>
import {
    getPermitForBusiness, getPublicHealthCertForPermit,
    getLiquorLicenseForBusiness
} from '@/utils/functions'
import {
    countyIsKilifi, countyIsKirinyaga
} from '@/utils/vue_helpers'
import { computed, ref } from 'vue';

const props = defineProps({
    business: {
        type: Object,
        default: null
    },
    permit: {
        type: Object,
        default: null
    }
})

const docTypes = {
    sbp: 'sbp',
    publicHealthCert: 'publicHealthCert',
    liquorLicense: 'liquorLicense'
}

const documents = computed(() => {
    const documentsToShow = []

    const permit = getPermitForBusiness(props.business)
    const permitApproved = permit?.permit_status === 'Approved' ?? false
    if (permitApproved) {
        documentsToShow.push({
            docName: 'SBP Certificate',
            docNumber: permit.sbp_number,
            docType: docTypes.sbp
        })
    }

    const publicHealthCert = getPublicHealthCertForPermit(permit)
    const publicHealthCertNotEmpty = !!publicHealthCert
    if (publicHealthCertNotEmpty) {
        const publicHealthCertName = `Public Health Certificate (${publicHealthCert.cert_type})`
        const publicHealthCertNumber = publicHealthCert.ph_cert_no
        documentsToShow.push({
            docName: publicHealthCertName,
            docNumber: publicHealthCertNumber,
            docType: docTypes.publicHealthCert
        })
    }

    const liquorLicense = getLiquorLicenseForBusiness(props.business)
    const liquorLicenseNotEmpty = !!liquorLicense
    if (liquorLicenseNotEmpty) {
        const liquorLicenseApproved = liquorLicense.status == 'Approved'
        if (liquorLicenseApproved) {
            const liquorLicenseNumber = liquorLicense.liquor_no
            documentsToShow.push({
                docName: 'Liquor License',
                docNumber: liquorLicenseNumber,
                docType: docTypes.liquorLicense
            })
        }
    }

    return documentsToShow
})

const canPrintDocuments = computed(() => {
    if (countyIsKilifi.value || countyIsKirinyaga.value) {
        return false
    }
    return true
})

const cannotPrintDialogOpen = ref(false)

const unprintableDocument = ref(null)

function onPrintClicked(document) {
    if (canPrintDocuments.value) {

    } else {
        unprintableDocument.value = document
        cannotPrintDialogOpen.value = true
    }
}
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="auto">
                <v-tabs>
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Generated Documents</v-tab>
                </v-tabs>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-simple-table>
                    <template #default>
                        <thead>
                            <tr>
                                <th class="text-left">Document Name</th>
                                <th class="text-left">Document Number</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-if="documents.length > 0">
                                <tr v-for="document in documents">
                                    <td>
                                        {{ document.docName }}
                                    </td>
                                    <td>
                                        {{ document.docNumber }}
                                    </td>
                                    <td class="text-right">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <v-btn icon class="purple--text" v-on="on" v-bind="attrs"
                                                    @click="onPrintClicked(document)"><v-icon>mdi-download-circle</v-icon></v-btn>
                                            </template>
                                            <span>Download</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </template>

                            <tr v-else>
                                <td colspan="3" class="text-center">No documents to show</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-dialog v-if="!!unprintableDocument" v-model="cannotPrintDialogOpen" max-width="600">
            <v-card>
                <v-card-title class="justify-space-between">
                    <span class="primary--text">Print Document</span>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn icon @click="cannotPrintDialogOpen = false" v-on="on"
                                v-bind="attrs"><v-icon>mdi-close</v-icon></v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text class="text-body-1">
                    <template v-if="unprintableDocument.docType === docTypes.sbp">
                        Please visit your sub county revenue office to collect your single business permit.
                    </template>
                    <template v-else-if="unprintableDocument.docType === docTypes.publicHealthCert">
                        Please visit your sub county Public Health Office to collect your public health certificate.
                    </template>
                    <template v-else-if="unprintableDocument.docType === docTypes.liquorLicense">
                        Please visit your sub county revenue office to collect your liquor license certificate.
                    </template>
                    <template v-else>
                        Please visit your sub county revenue office to collect your document.
                    </template>
                    <!-- Please go to the {{ permit.sub_county.sub_county_name }} office revenue cash office to collect your
                    document -->
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>