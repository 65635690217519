<script setup>
import { businessFeeData, businessFeeDataNotEmpty, businessFormData, businessFormDataNotEmpty } from '@/utils/vue_helpers'
import { getFormattedMoney, permitValueNotFalsy, checkKiriBusinessExemptFromCertainFees, checkIsLiquorPermit, } from '@/utils/functions'
import apiCall from '@/utils/api'
import { computed, onMounted, ref } from 'vue';
import { countyIsKilifi, countyIsKirinyaga } from '@/utils/vue_helpers';

const props = defineProps({
    business: {
        type: Object,
        default: null
    },
    permit: {
        type: Object,
        default: null
    },
    dialogOpen: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    isRenewal: {
        type: Boolean,
        default: true
    },
    latePenaltyAmount: {
        type: [Number, String],
        default: null
    }
})

const emit = defineEmits(['update:dialogOpen'])

const dialogModel = computed({
    get() {
        return props.dialogOpen
    },
    set(newVal) {
        emit('update:dialogOpen', newVal)
    }
})

const tradeLicenseCode = props.business.trade_license_code
const sbpLiquorFees = computed(() => businessFeeData.value.sbp_liquor_fees)
const sbpAdditionalFees = computed(() => businessFeeData.value.sbp_additional_fees)

const isLiquorPermit = computed(() => checkIsLiquorPermit(props.permit))

const kiriBusinessIsExempt = computed(() => {
    const feeCode = Number(props.business.trade_license_code)
    return (feeCode === 196 || feeCode === 201 || feeCode === 205 || feeCode === 206 || feeCode === 210 ||
        feeCode === 215 || feeCode === 220 || feeCode === 295 || feeCode === 296 || feeCode === 297)
})

const kilifiAdFee = ref(null)
const permitHasAdFees = computed(() => {
    return !!props.permit.advertisement_fee
})
const fetchingKilifiAdFee = ref(false)
async function setKilifiAdFee() {
    const signboardType = props.permit.advert_signboard_type
    const signboardSize = props.permit.advert_signboard_size
    const signboardNumberOfFaces = props.permit.advert_signboard_no_of_faces
    const shouldCalculateAdFee = permitHasAdFees.value && !!signboardType && !!signboardSize && !!signboardNumberOfFaces
    if (shouldCalculateAdFee) {
        const url = `/api/client/business/get-sbp-advert-fee?`
            + `advert_signboard_type=${signboardType}`
            + `&advert_signboard_size=${signboardSize}`
            + `&advert_signboard_no_of_faces=${signboardNumberOfFaces}`
        try {
            fetchingKilifiAdFee.value = true
            const response = await apiCall({
                url,
                method: "GET"
            })
            kilifiAdFee.value = response.advert_fee
        } finally {
            fetchingKilifiAdFee.value = false
        }

    }
}

function setSbpAppFee(feeList) {
    const sbpFee = businessFormData.value.sbp_fee.find(subpartFee => {
        const feeCodeMatchesTradeLicenseCode = subpartFee.fee_code === tradeLicenseCode
        const subpartZoneMatchesBusinessZone = subpartFee.zone_id === props.business.zone_id
        const subpartMatches = feeCodeMatchesTradeLicenseCode && subpartZoneMatchesBusinessZone
        return subpartMatches
    })
    const sbpFeeNotEmpty = sbpFee !== undefined
    if (sbpFeeNotEmpty) {
        props.business[sbpFee.fee_name] = sbpFee.amount
        feeList.push({
            title: sbpFee.fee_name,
            value: sbpFee.amount,
        })
    }
}

function setLiquorAppFee(feeList) {
    const liquorAppFee = sbpLiquorFees.value.find((sbpLiquorFee) => {
        const isLiquorAppFeeType = sbpLiquorFee.fee_type === 'Liquor Application Fee'
        const liquorFeeMatchesTradeLicenseCode = sbpLiquorFee.fee_code === tradeLicenseCode
        const feeMatches = isLiquorAppFeeType && liquorFeeMatchesTradeLicenseCode
        return feeMatches
    })
    const liquorAppFeeFound = liquorAppFee !== undefined
    if (liquorAppFeeFound) {
        props.business.liquorAppFee = liquorAppFee.amount
        feeList.push({
            title: 'Liquor Application Fee',
            value: liquorAppFee.amount
        })
    }
}

function setLiquorPublicHealthAppFee(feeList) {
    const liquorPublicHealthAppFee = sbpLiquorFees.value.find((sbpLiquorFee) => {
        const isLiquorPublicHealthAppFeeType = sbpLiquorFee.fee_type === 'Public Health Fee'
        const liquorFeeMatchesTradeLicenseCode = sbpLiquorFee.fee_code === tradeLicenseCode
        const feeMatches = isLiquorPublicHealthAppFeeType && liquorFeeMatchesTradeLicenseCode
        return feeMatches
    })
    const liquorPublicHealthAppFeeFound = liquorPublicHealthAppFee !== undefined
    if (liquorPublicHealthAppFeeFound) {
        props.business.liquor_ph_fee = liquorPublicHealthAppFee.amount
        feeList.push({
            title: 'Liquor Application Fee',
            value: liquorPublicHealthAppFee.amount
        })
    }
}

function setConservancyAppFee(feeList) {
    const conservancyFees = businessFeeData.value.sbp_conservancy_fees
    const conservancyFee = conservancyFees.find(conservancyFee => {
        const conservancyFeeMatchesZoneId = conservancyFee.zone_id === props.business.zone_id
        const conservancyFeeMatchesTradeLicenseCode = conservancyFee.fee_code === tradeLicenseCode
        const feeMatches = conservancyFeeMatchesZoneId && conservancyFeeMatchesTradeLicenseCode
        return feeMatches
    })
    const conservancyFeeFound = conservancyFee !== undefined
    if (conservancyFeeFound) {
        const feeAmount = conservancyFee.amount
        props.business.conservancy_fee_amount = feeAmount
        feeList.push({
            title: 'Conservancy Fee',
            value: feeAmount
        })
    }
}

function permitHasFee(feeKeyOnPermit) {
    return props.permit[feeKeyOnPermit] !== null
        && props.permit[feeKeyOnPermit] !== undefined
        && Number(props.permit[feeKeyOnPermit]) !== 0
        && Number(props.permit[feeKeyOnPermit]) !== 2
}

function setFee({
    title = null, feeDataKey, feeType = null, businessObjectKey, feeList,
    shouldMatchTradeLicensecode = true, customFeeCheck = null, shouldMatchBusinessActivity = false
}) {
    const feeTypeNotEmpty = !!feeType
    const titleNotEmpty = !!title
    const fee = businessFeeData.value[feeDataKey].find(fee => {
        if (customFeeCheck !== null) {
            const feeMatches = customFeeCheck(fee)
            return feeMatches
        }

        const feeCodeMatchesTradeLicenseCode = Number(fee.fee_code) === Number(props.business.trade_license_code)
        if (feeTypeNotEmpty) {
            const feeTypeMatches = fee.fee_type === feeType
            let feeMatches = feeTypeMatches
            if (shouldMatchTradeLicensecode) {
                feeMatches = feeMatches && feeCodeMatchesTradeLicenseCode
            }
            if (shouldMatchBusinessActivity) {
                const feeBusinessActivity = fee.business_activity_description.trim().toLowerCase()
                const businessActivity = props.business.business_activity.activity_name.trim().toLowerCase()
                const feeActivityMatchesBusinessActivity = feeBusinessActivity === businessActivity
                feeMatches = feeMatches && feeActivityMatchesBusinessActivity
            }
            return feeMatches
        }
        return feeCodeMatchesTradeLicenseCode
    })
    const feeNotEmpty = fee !== undefined
    if (feeNotEmpty) {
        const feeAmount = fee.amount
        props.business[businessObjectKey] = feeAmount
        let feeTitle = fee.fee_type
        if (titleNotEmpty) {
            feeTitle = title
        }
        feeList.push({
            title: feeTitle,
            value: feeAmount
        })
    } else {
        props.business[businessObjectKey] = null
    }
}

function setAdditionalFee({
    feeType, title, businessObjectKey
}, feeList) {
    const fee = sbpAdditionalFees.value.find(additionalFee => additionalFee.fee_type === feeType)
    const feeNotEmpty = fee !== undefined
    if (feeNotEmpty) {
        const feeAmount = fee.amount
        props.business[businessObjectKey] = feeAmount
        feeList.push({
            title,
            value: feeAmount
        })
    }
}

function getBillTotal(fees) {
    let total = 0
    for (const fee of fees) {
        const feeValueIsValidNumber = !isNaN(Number(fee.value))
        if (feeValueIsValidNumber) {
            total += Number(fee.value)
        }
    }
    return total
}

function getKilifiFees() {
    const requiredFees = []
    setSbpAppFee(requiredFees)

    if (props.isRenewal) {
        setAdditionalFee({
            feeType: 'Renewal Fee', title: 'Renewal Fee', businessObjectKey: 'sbp_renewal_fee'
        }, requiredFees)
    }

    if (permitValueNotFalsy(props.permit.sanitary_inspection)) {
        setFee({
            title: 'Sanitary Inspection Fee',
            feeDataKey: 'sbp_health_fees',
            feeType: 'Sanitary Inspection Fee',
            businessObjectKey: 'sanitary_inspection_fee',
            feeList: requiredFees
        })
    }

    if (permitHasFee('food_drugs_sale')) {
        setFee({
            title: 'Food Hygiene Licence',
            feeDataKey: 'sbp_health_fees',
            feeType: 'Food Hygiene Licence',
            businessObjectKey: 'food_hygiene_fee',
            feeList: requiredFees
        })
    }

    if (countyIsKilifi.value) {
        const shouldShowKilifiAdFees = permitHasAdFees.value && kilifiAdFee.value !== null
        if (shouldShowKilifiAdFees) {
            requiredFees.push({
                title: 'Signboard Advertisement Fees',
                value: kilifiAdFee.value
            })
        }
    }

    // Mandatory
    setFee({
        title: null,
        feeDataKey: 'sbp_fire_safety_fees',
        feeType: null,
        businessObjectKey: 'sbp_fire_safety_fee',
        feeList: requiredFees
    })


    if (permitHasFee('betting_fee')) {
        setFee({
            title: null,
            feeDataKey: 'sbp_betting_fees',
            feeType: null,
            businessObjectKey: 'sbp_betting_fee',
            feeList: requiredFees
        })
    }

    if (permitHasFee('solid_waste_fee')) {
        setFee({
            title: null,
            feeDataKey: 'sbp_solid_waste_fees',
            feeType: null,
            businessObjectKey: 'sbp_solid_waste_fee',
            feeList: requiredFees
        })
    }

    const total = getBillTotal(requiredFees)
    billTotal.value = total
    props.business.billItemsTotal = total
    return requiredFees
}

function getKirinyagaFees() {
    const requiredFees = []

    const kiriBusinessExemptFromCertainFees = checkKiriBusinessExemptFromCertainFees(props.business)

    if (!isLiquorPermit.value) {
        setSbpAppFee(requiredFees)

        if (props.isRenewal) {
            setFee({
                title: 'Renewal Fee',
                feeDataKey: 'sbp_additional_fees',
                feeType: 'Renewal Fee',
                businessObjectKey: 'sbp_renewal_fee',
                feeList: requiredFees,
                shouldMatchTradeLicensecode: false
            })
        }

        if (!kiriBusinessExemptFromCertainFees) {
            setFee({
                title: 'Public Health Certificate',
                feeDataKey: 'sbp_health_fees',
                feeType: 'Public Health Certificate Fee',
                businessObjectKey: 'health_certificate_fee',
                shouldMatchBusinessActivity: true,
                feeList: requiredFees
            })

            setFee({
                title: 'Public Health Inspection',
                feeDataKey: 'sbp_health_fees',
                feeType: 'Public Health Inspection Fee',
                businessObjectKey: 'health_inspection_fee',
                shouldMatchBusinessActivity: true,
                feeList: requiredFees
            })
        }

        setFee({
            title: 'Conservancy Fee',
            feeDataKey: 'sbp_conservancy_fees',
            feeType: null,
            businessObjectKey: 'conservancy_fee_amount',
            feeList: requiredFees,
            shouldMatchTradeLicensecode: false,
            customFeeCheck: (fee) => {
                const feeCodeMatchesTradeLicenseCode = Number(fee.fee_code) === Number(props.business.trade_license_code)
                const feeZoneMatchesBusinessZone = Number(fee.zone_id) === Number(props.business.zone_id)
                const feeMatches = feeCodeMatchesTradeLicenseCode && feeZoneMatchesBusinessZone
                return feeMatches
            }
        })

        if (!kiriBusinessExemptFromCertainFees) {
            setFee({
                title: 'Advertisement Fee',
                feeDataKey: 'sbp_additional_fees',
                feeType: 'Advertisement Fee',
                businessObjectKey: 'sbp_advert_fee',
                feeList: requiredFees,
                shouldMatchTradeLicensecode: false
            })
        }

        if (permitHasFee('sublet')) {
            setFee({
                title: 'Sublet Fee',
                feeDataKey: 'sbp_additional_fees',
                feeType: 'Sublet Fee',
                businessObjectKey: 'sbp_sublet_fee',
                feeList: requiredFees,
                shouldMatchTradeLicensecode: false
            })
        }

        if (permitHasFee('ownland')) {
            setFee({
                title: 'Ownland',
                feeDataKey: 'sbp_additional_fees',
                feeType: 'Own Land Fee',
                businessObjectKey: 'sbp_own_land_fee',
                feeList: requiredFees,
                shouldMatchTradeLicensecode: false
            })
        }
    } else {
        setFee({
            title: 'Liquor Application Fee',
            feeDataKey: 'sbp_liquor_fees',
            feeType: 'Liquor Application Fee',
            businessObjectKey: 'liquor_app_fee',
            feeList: requiredFees
        })

        setFee({
            title: 'Liquor Public Health Fee',
            feeDataKey: 'sbp_liquor_fees',
            feeType: 'Public Health Fee',
            businessObjectKey: 'liquor_ph_fee',
            feeList: requiredFees
        })
    }

    const total = getBillTotal(requiredFees)
    billTotal.value = total
    props.business.billItemsTotal = total
    return requiredFees
}

function addLateFees(feeList) {
    const permitHasLateFees = !!props.latePenaltyAmount
    if (permitHasLateFees) {
        feeList.push({
            title: 'Penalty On Failure To Pay Single Business Permit',
            value: props.latePenaltyAmount
        })
        billTotal.value = getBillTotal(feeList)
    }
}

const billTotal = ref(0)
const fees = computed(() => {
    let feeList = null
    if (countyIsKilifi.value) {
        feeList = getKilifiFees()
    } else if (countyIsKirinyaga.value) {
        feeList = getKirinyagaFees()
    }
    if (!isLiquorPermit.value) {
        addLateFees(feeList)
    }
    return feeList
})

onMounted(async () => {
    if (countyIsKilifi.value) {
        await setKilifiAdFee()
    }
})
</script>

<template>
    <v-dialog v-model="dialogModel" persistent max-width="800">
        <v-card>
            <v-card-title class="primary white--text">
                Bill Items Summary
            </v-card-title>

            <v-simple-table>
                <template #default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <div class="font-weight-bold primary--text">Business Name</div>
                            </th>
                            <th>
                                {{ business.business_name }}
                            </th>
                        </tr>
                        <tr>
                            <th class="text-left">
                                <div class="font-weight-bold primary--text">Business Type</div>
                            </th>
                            <th>
                                {{ business.business_type }}
                            </th>
                        </tr>
                        <tr>
                            <th class="text-left">
                                <div class="font-weight-bold primary--text">Business Activity</div>
                            </th>
                            <th>
                                {{ business.business_activity.activity_name }}
                            </th>
                        </tr>
                    </thead>

                    <thead>
                        <tr>
                            <th class="text-left">
                                <div class="primary--text font-weight-bold">Description</div>
                            </th>
                            <th class="text-left">
                                <div class="primary--text font-weight-bold">Amount</div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="fee in fees">
                            <td>{{ fee.title }}</td>
                            <td>{{ `Kshs. ${getFormattedMoney(fee.value)}` }}</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="font-weight-bold primary--text">Total Amount</div>
                            </td>
                            <td>{{ `Kshs. ${getFormattedMoney(billTotal)}` }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-card-actions>
                <v-spacer />
                <v-btn color="error" class="text-none" :disabled="loading" @click="$emit('update:dialogOpen', false)">
                    Cancel
                </v-btn>
                <v-btn color="success" :loading="props.loading || fetchingKilifiAdFee" class="text-none"
                    @click="$emit('renewSbpClicked')">
                    Renew Permit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>