var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_c('v-tab',{staticClass:"text-none"},[_vm._v("Bills And Receipts")])],1)],1)],1),_c('v-row',[_c('v-col',{style:({ position: 'relative' }),attrs:{"cols":"12"}},[_c(_setup.LoadingArea,{attrs:{"loading":_vm.fetchingBillsAndReceipts}},[_c(_setup.Table,{attrs:{"headers":_setup.tableHeaders,"items":_vm.fetchedBillsAndReceipts,"loading":_vm.fetchingBillsAndReceipts,"emptyText":"No Bills Found"},scopedSlots:_vm._u([{key:"item.date",fn:function({ item: billReceipt }){return [_vm._v(" "+_vm._s(_setup.getFormattedBillDate(billReceipt.date))+" ")]}},{key:"item.amount",fn:function({ item: billReceipt }){return [_vm._v(" "+_vm._s(_setup.getFormattedMoney(billReceipt.amount))+" ")]}},{key:"item.status",fn:function({ item: billReceipt }){return [_c('span',{class:{
                'font-weight-bold': true,
                'green--text': billReceipt.status === 'Paid',
                'red--text': billReceipt.status === 'Unpaid',
              }},[_vm._v(_vm._s(billReceipt.status))])]}},{key:"item.download",fn:function({ item: billReceipt }){return [(_setup.isBill(billReceipt))?_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadBill,"inTable":""},on:{"click":function($event){return _vm.$emit('printBillClicked', billReceipt)}}}):_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadReceipt,"inTable":""},on:{"click":function($event){return _vm.$emit('printReceiptClicked', billReceipt)}}})]}},(_setup.hasAtLeastOneBillToPay)?{key:"item.pay",fn:function({ item: billReceipt }){return [(_setup.isBill(billReceipt) && billReceipt.status === 'Unpaid')?_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _setup.onPayBillClicked(billReceipt)}}}):_c('span')]}}:null],null,true)}),(false)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Number")]),_c('th',{staticClass:"text-left"},[_vm._v("Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Amount")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-right"},[_vm._v("Action")])])]),_c('tbody',[(_setup.arrayNotEmpty(_vm.fetchedBillsAndReceipts))?[_vm._l((_vm.fetchedBillsAndReceipts),function(billReceipt){return [(_setup.isBill(billReceipt))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(billReceipt.type))]),_c('td',[_vm._v(_vm._s(billReceipt.number))]),_c('td',[_vm._v(_vm._s(_setup.getFormattedBillDate(billReceipt.date)))]),_c('td',[_vm._v(_vm._s(_setup.getFormattedMoney(billReceipt.amount)))]),_c('td',{class:{
                            'font-weight-bold': true,
                            'green--text': billReceipt.status === 'Paid',
                            'red--text': billReceipt.status === 'Unpaid',
                          }},[_vm._v(" "+_vm._s(billReceipt.status)+" ")]),_c('td',{attrs:{"align":"right"}},[_c(_setup.ActionsContainer,[_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadBill,"inTable":""},on:{"click":function($event){return _vm.$emit('printBillClicked', billReceipt)}}}),(billReceipt.status === 'Unpaid')?_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _setup.onPayBillClicked(billReceipt)}}}):_vm._e()],1)],1)])]}}],null,true)},[_c('div',[_c('span',{staticClass:"mb-2"},[_vm._v("Bill Items")]),_c('v-simple-table',{staticClass:"background mb-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((billReceipt.bill_items),function(billItem){return _c('tr',[_c('td',[_vm._v(" "+_vm._s(billItem.fee_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_setup.getFormattedMoney(billItem.sub_total))+" ")])])}),0)]},proxy:true}],null,true)})],1)]):_c('tr',[_c('td',[_vm._v(_vm._s(billReceipt.type))]),_c('td',[_vm._v(_vm._s(billReceipt.number))]),_c('td',[_vm._v(_vm._s(_setup.getFormattedBillDate(billReceipt.date)))]),_c('td',[_vm._v(_vm._s(_setup.getFormattedMoney(billReceipt.amount)))]),_c('td',{class:{
                        'font-weight-bold': true,
                        'green--text': billReceipt.status === 'Paid',
                        'red--text': billReceipt.status === 'Unpaid',
                      }},[_vm._v(" "+_vm._s(billReceipt.status)+" ")]),_c('td',{attrs:{"align":"right"}},[_c(_setup.ActionsContainer,[_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadReceipt,"inTable":""},on:{"click":function($event){return _vm.$emit('printReceiptClicked', billReceipt)}}})],1)],1)])]})]:[_c('td',{attrs:{"colspan":"6","align":"center"}},[_c('div',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.fetchingBillsAndReceipts ? "Loading" : "No bills or receipts")+" ")])])]],2)]},proxy:true}],null,false,594451472)}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }